<template>
  <div class="bid_wrap license_wrap vote_detail judge_after_wrap">
    <div class="bid_main_wrap">
      <div class="bid_content_wrap">
        <div class="bid_title_wrap">
          <h1 class="title">{{$t('item')}}</h1>
        </div>

        <div class="content_wrap">
          <div class="img_wrap">
            <div class="b_img">
              <img src="@/assets/image/main/bid_img1.jpg"/>
            </div>
            <swiper class="small_img_wrap" ref="swiper" :options="swiperOption2" :slidesPerView="'auto'">
              <swiper-slide class="s_img">
                <img src="@/assets/image/main/bid_img2.jpg"/>
              </swiper-slide>
              <swiper-slide class="s_img">
                <img src="@/assets/image/main/bid_img3.jpg"/>
              </swiper-slide>
              <swiper-slide class="s_img">
                <img src="@/assets/image/main/bid_img4.jpg"/>
              </swiper-slide>
              <swiper-slide class="s_img">
                <img src="@/assets/image/main/bid_img5.jpg"/>
              </swiper-slide>
            </swiper>
          </div>
          <div class="inform_wrap">
            <div class="inform_header">
              <div class="i_header_top">
                <div class="share_btn"></div>
                <div class="right">
                  <div class="icon_wrap">
                    <img  class="icon heart" src="@/assets/image/main/heart_no_color.png" alt="">
                    <div class="icon_count">251</div>
                  </div>
                  <div class="icon_wrap">
                    <img  class="icon eye" src="@/assets/image/main/eye1.png" alt="">
                    <div class="icon_count">1,251</div>
                  </div>
                </div>
              </div>
              <div class="inform_h_header">
                <ul class="thumb_img">
                  <li>
                    <img src="@/assets/image/main/bid_thumb1.png">
                  </li>
                  <li>
                    <img src="@/assets/image/main/bid_thumb2.png">
                  </li>
                </ul>
              </div>
              <div class="time_remain"></div>
              <div class="header_title">
                <h3 class="title_left">{{$t('picture')}}</h3>
                <h3 class="title_right">{{$t('input_subject')}}</h3>
              </div>
              <div class="edition">
                <span><span>{{$t('edition')}}</span> 1/10</span>

              </div>
            </div>
            <div class="inform_content">
              <div class="hashtag_wrap">
                <ul>
                  <li>
                    <span>#해시태그</span>
                  </li>
                  <li>
                    <span>#해시태그</span>
                  </li>
                  <li>
                    <span>#해시태그</span>
                  </li>
                </ul>
              </div>
              <div class="content_top">
                <ul>
                  <li>
                    <h3 class="sub_title">{{$t('n_vote')}}</h3>
                    <span>123,456(32%)</span>
                  </li>
                </ul>
              </div>
              <div class="evaluation_box">
                <el-collapse v-model="evaulation" class="ev_wrap">
                  <el-collapse-item v-for="data in 1" :key="data" class="ev_content_box">
                    <template slot="title">
                      <div class="amount">{{$t('point')}}<span>80</span>{{$t('jum')}}</div>
                    </template>
                    <h3 class="transition-box">{{$t('r_point')}}</h3>
                    <div class="transition-box border_box">입력한 배점이유 노출... 입력한 배점이유 노출... 입력한 배점이유 노출...</div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </div>
        </div>
        <div class="tab_detail_wrap">

          <div class="item_tab">
            <ul class="tab_wrap">
              <li @click="changeTab('item')" :class="{ active : tab === 'item' }" class="item_tab1">
                <div class="tab">{{ $t('item') }}</div>
              </li>
              <li @click="changeTab('block')" :class="{ active : tab === 'block' }" class="blockchain_tab1">
                <div class="tab">{{ $t('i_block') }}</div>
              </li>
              <li @click="changeTab('history')" :class="{ active : tab === 'history' }" class="nft_tab1">
                <div class="tab">{{ $t('nft_history') }}</div>
              </li>
            </ul>
            <div class="info_detail" v-if=" tab === 'item'">
              <h3>{{$t('detail_inform')}}</h3>
              <ul>
                <li>
                  <h3>{{$t('t_video')}}</h3>
                  <span>04/02/2021 14:52:37 +0000</span>
                </li>
                <li>
                  <h3>{{$t('v_country')}}</h3>
                  <span>South of Korea</span>
                </li>
                <li>
                  <h3>{{$t('v_si')}}</h3>
                  <span>Gyeongsangbuk-do</span>
                </li>
                <li>
                  <h3>{{$t('v_city')}}</h3>
                  <span>Gyeongju-si</span>
                </li>
                <li>
                  <h3>{{$t('size')}}</h3>
                  <span>4131*6196 px</span>
                </li>
                <li>
                  <h3>{{$t('certi')}}</h3>
                  <span>O</span>
                </li>
              </ul>
              <div class="description_wrap">
                <h3>{{$t('i_inform')}}</h3>
                <div>
                  <p>아이템 설명이 들어가는 곳입니다.아이템 설명이 들어가는 곳입니다.아이템 설명이 들어가는 곳입니다.<br>
                    아이템 설명이 들어가는 곳입니다.아이템 설명이 들어가는 곳입니다.</p>
                </div>
              </div>
              <div class="nick_item">
                <h3>닉네임의 ITEM</h3>
                <swiper class="nick_swiper" ref="swiper" :options="swiperOption" :slidesPerView="'auto'">
                  <swiper-slide class="nick_swiper_slide">
                    <div class="nick_card"></div>
                  </swiper-slide>
                  <swiper-slide class="nick_swiper_slide" >
                    <div class="nick_card second"></div>
                  </swiper-slide>
                  <swiper-slide class="nick_swiper_slide" >
                    <div class="nick_card third"></div>
                  </swiper-slide>
                  <swiper-slide class="nick_swiper_slide" >
                    <div class="nick_card fourth"></div>
                  </swiper-slide>
                </swiper>
                <div class="back_btn"></div>
                <div class="next_btn"></div>
              </div>
            </div>
          </div>

          <div class="block_tab" v-if=" tab === 'block'">
            <div class="info_detail">
              <h3>{{$t('i_block')}}</h3>
              <ul>
                <li>
                  <h3>Contract Address</h3>
                  <span>0x8c5aCF6dBD24c66e6FD44d4A4C3d7a2D955AAad2</span>
                </li>
                <li>
                  <h3>Token ID</h3>
                  <span>112621853198944136368633026763678309454037261161069941304530857 5119324778769</span>
                </li>
                <li>
                  <h3>Token Name</h3>
                  <span>Bruce Lee's flying kick sends him into the koi pond in The Chinese Connection</span>
                </li>
                <li>
                  <h3>Original Image</h3>
                  <span>https://d1iczm3wxxz9zd.cloudfront.net/ff102625-334f-4c06-ac78- 40817b23cded/000000- 0000000000/11262185319894413636863302676367830945403726116106 99413045308575119324778769/ITEM_PREVIEW2.jpg</span>
                </li>
                <li>
                  <h3>Original Creator</h3>
                  <span>0x027d6ac70F09300e4a069008BFB09bb67E57006e</span>
                </li>
                <li>
                  <h3>Token Metadata</h3>
                  <span>"symbol": "Mintable Gasless store", "resellable": true, "movie": "The Chinese Connection", "description": "&#60;p&#62;FlixU is a movie studio company that was started 18 years ago.
                        We distribute thousand of movies. We are now working with NFTs.&#60;/p&#62;&#60;p&#62;What do you get:&#60;/p&#62;&#60;p&#62;◦ The clip&#60;/p&#62;&#60;p&#62;◦ A certificate that we can mail to your home of
                        your new ownership&#60;/p&#62;&#60;p&#62;◦ A memory stick with the original file&#60;/p&#62;&#60;p&#62; ◦ A license that you can use the movie for any purpose for 15 years.&#60;/p&#62;&#60;p&#62;For questions
                        &#60;/p&#62;&#60;p&#62; Samkl@oddpost.com&#60;/p&#62;", "title": "Bruce Lee's flying kick sends him into the koi pond in The Chinese  Connection", "apiURL": "mintable_gasless/", "auctionType":
                        "Fixed", "gasless": true, "image":"https://d1iczm3wxxz9zd.cloudfront.net/ff102625-334f-4c06-ac78
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <div class="history_tab" v-if=" tab === 'history'">

            <div class="info_detail">
              <h3>{{$t('nft_history')}}</h3>
              <div class="more_view_btn2" @click="movePage('/openmarket/1/history')">More+</div>
              <table class="table_wrap" width="100%"  border="0" cellspacing="0" cellpadding="0">
                <colgroup>
                  <col style="max-width: 25%; width: 25%;">
                  <col style="max-width: 16.66%; width: 16.66%;">
                  <col style="max-width: 16.66%; width: 16.66%;">
                  <col style="max-width: 16.66%; width: 16.66%;">
                  <col style="max-width: 25%; width: 25%;">
                </colgroup>
                <tr height="52"  class="t_top">
                  <th scope="col" class="h_t_th">Event</th>
                  <th scope="col" class="h_t_th">Price</th>
                  <th scope="col" class="h_t_th">From</th>
                  <th scope="col" class="h_t_th">To</th>
                  <th scope="col" class="h_t_th">Date</th>
                </tr>
                <tr height="55" class="table_content">
                  <td width="25%">{{$t('s_bid')}}</td>
                  <td width="16.66%">-</td>
                  <td width="16.66%">nickname</td>
                  <td width="16.66%">nickname</td>
                  <td width="25%">2021-07-22 10:33</td>
                </tr>
                <tr height="55" class="table_content">
                  <td width="25%">{{$t('bid_enroll')}}</td>
                  <td width="16.66%">-</td>
                  <td width="16.66%">nickname</td>
                  <td width="16.66%">nickname</td>
                  <td width="25%">2021-07-22 10:33</td>
                </tr>
                <tr height="55" class="table_content">
                  <td width="25%">{{$t('buy_license')}}</td>
                  <td width="16.66%">10,000</td>
                  <td width="16.66%">nickname</td>
                  <td width="16.66%">nickname</td>
                  <td width="25%">2021-07-22 10:33</td>
                </tr>
                <tr height="55" class="table_content">
                  <td width="25%">{{$t('create_nft')}}</td>
                  <td width="16.66%">-</td>
                  <td width="16.66%">nickname</td>
                  <td width="16.66%">nickname</td>
                  <td width="25%">2021-07-22 10:33</td>
                </tr>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>
<!--    <terms-modal></terms-modal>-->
  </div>


</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import util from "@/mixins/util";
export default {
  name: "JudgeListAfterLayout",
  mixins: [],
  components: {
    Swiper,
    SwiperSlide
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
      tab : 'item',

      show3: true,
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 24,
        grabCursor:true,
        autoWidth:true,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
          clickable: true
        },
        navigation: {
          nextEl: '.next_btn',
          prevEl: '.back_btn',
        },
      },
      swiperOption2: {
        slidesPerView: 4,
        spaceBetween: 8,
        grabCursor:true,
        autoWidth:true,
        loop: true,
      }
    }
  },
  beforeRouterEnter() {},
  created() {
    this.tab = this.$route.query.tab;
    if (util.isEmpty(this.tab)) {
      this.changeTab('item');
    };
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    movePage(url) {
      this.$router.push(url);
    },
    changeTab(tab) {
      this.tab = ''
      this.$nextTick(() => {
        this.tab = tab;
      })
      this.$router.replace(`${this.$route.path}?tab=${tab}`);
    },
  },
  watch: {
    "$route.fullPath": {
      deep: true,
      handler: function (val, oldVal) {
        if (this.tab != this.$route.query.tab) {
          this.changeTab(this.$route.query.tab);
        }
      }
    }
  },
}
</script>

<style scoped>

</style>